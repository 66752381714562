<template>
    <div>
        <v-title v-if="Object.keys(order).length" :title="`${order.orderNumber} - Orders`"></v-title>
        <transition appear>
            <router-view ref="routeview"></router-view>
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'OrderWrapper',
    computed: {
        ...mapState('order', ['order']),
        breadCrumbId () {
            if (!this.order) {
                return '...'
            }
            return this.order.orderNumber
        },
    },
}
</script>
